import { CompositeNavigationProp, RouteProp } from "@react-navigation/core";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { HelpStackParamList } from "../../navigation/HelpNavigator";
import DesktopMainHelpScreen from "../../screens/MainHelpScreen";
import { DrawerParamList } from "../../types";
import useMobileVersion from "../hooks/useMobileVersion";
import MobileMainHelpScreen from "../screens/mobile/MainHelpScreen";

interface Props {
    route : RProp;
    navigation : NProp
}

type NProp = CompositeNavigationProp<DrawerNavigationProp<DrawerParamList>, StackNavigationProp<HelpStackParamList>>
type RProp = RouteProp<HelpStackParamList, 'StartHelp'>

export default function MainHelpSelector({navigation, route} : Props) {

    const mobile = useMobileVersion()

    if(mobile) {
        return(<MobileMainHelpScreen />)
    }

    return(
        <DesktopMainHelpScreen route={route} navigation={navigation} />
    )
}