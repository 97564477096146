import { RouteProp } from '@react-navigation/core';
import Fuse from 'fuse.js';
import React, { useMemo } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import Footer from '../components/Footer';
import HelpHeaderImage from '../components/help/HelpHeaderImage';
import PopularFAQs from '../components/help/PopularFAQs';
import SearchSumup from '../components/search/SearchSumup';
import { getAllFAQs } from '../src/utils/FAQManager';
import { FAQ } from '../src/constants/Structs';
import { HelpStackParamList } from '../navigation/HelpNavigator';
import Locale from '../src/utils/locale/Locale';

type HelpSearchRouteProp = RouteProp<HelpStackParamList, "Search">;

interface Prop {
    route: HelpSearchRouteProp;
}

export default function HelpSearchScreen({route} : Prop) {

    const {querry} = route.params;

    const options = {
        includeScore: true,
        keys: ['title', 'description']
    }

    const fuse = useMemo(() => new Fuse(getAllFAQs(), options), []);

    const list = useMemo(() => fuse.search(querry), [fuse, querry]);

    const styles = StyleSheet.create({
        container: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        resultctn: {
            width: "80%",
            marginVertical: 50,
        },
        title: {
            fontSize: 25,

        },
        pfaqs: {
            width: "80%",
            
        }
    });

    const renderItem = ({item} : {item : Fuse.FuseResult<FAQ>}) => (
        <SearchSumup item={item.item} />
    )

    return(
        <View style={styles.container}>
            <HelpHeaderImage input={querry} />
            <View style={styles.resultctn}>
                <Text style={styles.title}>{Locale.t("search_result", {results: list.length, query: querry})}</Text>
                {list.length > 0 ? <FlatList renderItem={renderItem} data={list} numColumns={2} columnWrapperStyle={{justifyContent: 'space-between'}} /> : null}
            </View>
            <PopularFAQs style={styles.pfaqs} />
            <Footer />
        </View>
    )
}