export default {
    home: "Startseite",
    feature1_title: "Es beginnt alles mit einem Blick",
    feature1_description: "Finde Leute wieder, denen du unterwegs begegnet bist",
    findus: "Probiere es gleich aus",
    help : "Hilfe",
    help_needed: "Hilfe gefällig?",
    help_related_articles: "Verwandte Themen",
    terms: "Rechtliches",
    popular_faq: "Beliebte Fragen",
    search_result: "%{results} Ergebnisse für '%{query}' erhalten",
    footer_aboutus: "Über uns",
    footer_contact: "Kontakt",
    legal: "Impressum",
    tos: "Nutzungsbedingungen",
    privacy_policy: "Datenschutzerklärung",
    //Help section
    first_ad: "Erste Hilfe",
    fa_howdoesitwork: "Wie funktioniert, Was?",
    help_general: "Allgemeine Hilfe",
}