import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { HelpStackParamList } from "../../../navigation/HelpNavigator";
import SmallFAQItem from "../../components/help/SmallFAQListItem";
import { FAQ } from "../../constants/Structs";
import { getFAQ, getRelatedFAQs } from "../../utils/FAQManager";
import Locale from "../../utils/locale/Locale";
import MarkDownComponent from "../../components/general/MarkDownComponent";

type RProp = RouteProp<HelpStackParamList, 'Article'>
type NProp = StackNavigationProp<HelpStackParamList>

interface Props {
    route : RProp;
    navigation : NProp
}

export default function ArticleScreen({route, navigation} : Props) {

    const {id} = route.params;

    const faq = getFAQ(id);

    useEffect(() => {
        navigation.setOptions({headerTitle: faq?.title})
    }, [faq])

    if(!faq) {
        return null
    }

    const renderRelated = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} onPress={() => navigation.replace('Article', {id: item.id})} />
    )

    const keyRelated = (item : FAQ, index : number) => (item.id)

    return(
        <View style={styles.articlectn}>
            <View style={styles.faqctn}>
                <Text style={styles.faqtitle}>{faq.title}</Text>
                {
                    faq.markdown ? (
                        <MarkDownComponent>{faq.markdown}</MarkDownComponent>
                    ) : (
                        <Text style={styles.faqdesc}>{faq.description}</Text>
                    )
                }
                <View style={styles.relatedctn}>
                    <Text style={{fontWeight: 'bold', fontSize: 22}}>{Locale.t("help_related_articles")}</Text>
                    <FlatList style={{marginVertical: 20}} data={getRelatedFAQs(faq.id)} renderItem={renderRelated} keyExtractor={keyRelated} />
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    faqctn: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "100%",
    },
    faqtitle: {
        fontSize: 25,
        marginBottom: 20
    },
    faqdesc: {
        fontSize: 18,
    },
    articlectn: {
        flexDirection : 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        marginVertical: 50,
        width: "90%"
    },
    relatedctn: {
        marginTop: 40,
        width: "100%"
    }
});
