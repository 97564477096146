import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useMemo, useRef } from "react";
import { FlatList, Text, View, StyleSheet } from "react-native";
import { HelpStackParamList } from "../../navigation/HelpNavigator";
import { Category } from "../../src/constants/Structs";
import CategoryMemory from "../../src/utils/CategoryMemory";
import CategorySumup from "../categories/CategorySumup";
import ArticleCategoryList from "./ArticleCategoryList";
import FAQList from "./FAQList";

interface Props{
    CategoryID : string
    SubCategoryID? : string
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    viewctn: {
        
    },
    faqctn: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "80%",
        marginStart: 40
    },
    faqtitle: {
        fontSize: 30,
        marginBottom: 20
    },
    faqdesc: {
        fontSize: 20,
    },
    articlectn: {
        flexDirection : 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        marginVertical: 50,
        width: "80%"
    },
    relatedctn: {
        marginTop: 40,
        width: "100%"
    }
});

type NProp = StackNavigationProp<HelpStackParamList>

export default function CategoryView({CategoryID, SubCategoryID} : Props) {

    const category = useMemo(() => CategoryMemory.getCategory(CategoryID), [CategoryID]);

    const subcategory = useMemo(() => CategoryMemory.getSubCategory(CategoryID), [CategoryID]);

    const navigation = useNavigation<NProp>()

    const ref = useRef<FlatList<Category>>();

    useEffect(() => {
        if(SubCategoryID && subcategory){
            const index = subcategory.findIndex((v) => v.id === SubCategoryID);
            if(index > 0){
                ref.current?.scrollToIndex({index: index, animated: true});
            }
        }
    }, [])

    const renderCategory = ({item} : {item : Category}) => (
        <CategorySumup item={item} onPress={(item) => navigation.navigate('StartHelp', {article: item.id})} />
    )

    const keygen = (item : Category, index : number) => (item.id);

    return(
        <View style={styles.articlectn}>
            <ArticleCategoryList activecategory={CategoryID} style={{flex : 1, maxWidth : 300}} />
            <View style={styles.faqctn}>
                <Text style={styles.faqtitle}>{category.title}</Text>
                <View>
                    {
                        category && (
                            <FAQList category={category} style={{marginBottom: 15}} onPress={(item) => navigation.navigate('StartHelp', {article: item.id})} />
                        )
                    }   
                    {
                        subcategory && (
                            <FlatList data={subcategory} renderItem={renderCategory} ref={ref} numColumns={3} keyExtractor={keygen} />
                        )
                    }                
                </View>
            </View>
        </View>
)
}