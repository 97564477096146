import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { HelpStackParamList } from "../../../../navigation/HelpNavigator";
import { Category } from "../../../constants/Structs";
import { maincategories } from "../../../data/Categorys";
import FAQListItem from '../../help/FAQListItem';

type NProp = StackNavigationProp<HelpStackParamList, 'StartHelp'>

export default function FAQCategories() {

    const navigation = useNavigation<NProp>()

    const render = ({item} : {item : Category}) => (
        <FAQListItem item={item} style={{}} onPress={() => navigation.push('Category', {id: item.id})} />
    )

    return(
        <View style={styles.faqlistctn}>
            <Text style={styles.lowertitle}>Categories</Text>
            <FlatList style={{width: '100%', alignSelf: 'center'}} contentContainerStyle={{}} data={maincategories} renderItem={render} />
        </View>
    )
}

const styles = StyleSheet.create({
    faqlistctn: {
        margin: 20,
        alignItems: 'flex-start',
        alignSelf: 'center',
        width: '80%'
      },
    lowertitle: {
      fontSize: 30,
      fontWeight: '400',
      color: 'black',
      textAlign: 'left'
    },
    text: {
      color: "black"
    },
    categories: {
      width: "80%"
    }
});