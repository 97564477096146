import { RouteProp, useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useEffect, useMemo } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import CategorySumup from "../../../components/categories/CategorySumup";
import Footer from "../../../components/Footer";
import FAQList from "../../../components/help/FAQList";
import { HelpStackParamList } from "../../../navigation/HelpNavigator";
import { Category } from "../../constants/Structs";
import CategoryMemory from "../../utils/CategoryMemory";

type CategoryRouteProp = RouteProp<HelpStackParamList, "Category">;

interface Props {
    route : CategoryRouteProp;
}

type NProp = StackNavigationProp<HelpStackParamList>

export default function CategoryScreen({route} : Props) {

    const {id, sub} = route.params

    const category = useMemo(() => CategoryMemory.getCategory(id), [id]);

    const subcategory = useMemo(() => CategoryMemory.getSubCategory(id), [id]);

    const navigation = useNavigation<NProp>()

    useEffect(() => {
        navigation.setOptions({headerTitle: category.title})
    }, [category])

    const renderCategory = ({item} : {item : Category}) => (
        <CategorySumup item={item} onPress={(item) => navigation.navigate('Article', {id: item.id})} />
    )

    const keygen = (item : Category, index : number) => (item.id);

    return(
        <View style={styles.container} >
            <View style={styles.faqctn}>
                    <Text style={styles.faqtitle}>{category.title}</Text>
                    <View>
                        {
                            category && (
                                <FAQList category={category} style={{marginBottom: 15}} onPress={(item) => navigation.navigate('Article', {id: item.id})} />
                            )
                        }   
                        {
                            subcategory && (
                                <FlatList data={subcategory} renderItem={renderCategory} numColumns={3} keyExtractor={keygen} />
                            )
                        }  
                    </View>
            </View>
            <Footer />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    viewctn: {
        
    },
    faqctn: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "80%",
        marginStart: 40
    },
    faqtitle: {
        fontSize: 30,
        marginBottom: 20
    },
    faqdesc: {
        fontSize: 20,
    },
    articlectn: {
        flexDirection : 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        marginVertical: 50,
        width: "80%"
    },
    relatedctn: {
        marginTop: 40,
        width: "100%"
    }
});