import React from 'react';
import { useInView } from 'react-intersection-observer';
import { View, Text, StyleSheet, Image } from 'react-native';
import { animated, useSpring } from 'react-spring/native';
import { Feature as FItem } from '../src/constants/Structs';
import { useTheme } from '../hooks/useTheme';

interface Prop{
    item : FItem;
}

export default function Feature({item} : Prop){

    const Style = useTheme();
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.7,
        initialInView: false,
      });
    const spring = useSpring(inView ? {opacity: 1} : {opacity: 0})
    const AnimatedView = animated(View);

    console.log("inView",inView)

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            minHeight: 700,
        },
        title: {
            fontSize: 20,
            color: Style.accentcolor,
        },
        description: {
            fontSize: 30,
        }
    })

    return(
        <View style={styles.container} ref={ref}>
            <AnimatedView style={{...spring}}>
                <Text style={styles.title}>{item.title.toUpperCase()}</Text>
                <Text style={styles.description}>{item.description}</Text>
            </AnimatedView>
            <View>
                <Image style={{width: 400, height: 750}} source={item.image} resizeMode='contain' />
            </View>
        </View>
    )
}