import { RouteProp } from '@react-navigation/core';
import React, {useRef} from 'react';
import { useEffect } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Footer from '../components/Footer';
import ArticleCategoryList from '../components/help/ArticleCategoryList';
import HelpHeaderImage from '../components/help/HelpHeaderImage';
import { getFAQ, getRelatedFAQs } from '../src/utils/FAQManager';
import { FAQ } from '../src/constants/Structs';
import { HelpStackParamList } from '../navigation/HelpNavigator';
import SmallFAQItem from '../src/components/help/SmallFAQListItem';

type ArticleScreenRouteProp = RouteProp<HelpStackParamList, 'Article'>;

interface Props{
    route : ArticleScreenRouteProp;
}

export default function ArticleScreen( { route } : Props){

    const {id} = route.params;
    const faq = getFAQ(id);
    const ref = useRef<ScrollView>()

    useEffect(() => {
            ref.current!.scrollToEnd()
            
        
    }, [faq])

    if(!faq){
        return (
            <Text>Id nicht gefunden</Text>
        )
    }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        viewctn: {
            
        },
        faqctn: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: "80%",
            marginStart: 40
        },
        faqtitle: {
            fontSize: 30,
            marginBottom: 20
        },
        faqdesc: {
            fontSize: 20,
        },
        articlectn: {
            flexDirection : 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            marginVertical: 50,
            width: "80%"
        },
        relatedctn: {
            marginTop: 40,
            width: "100%"
        }
    });

    const renderRelated = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} />
    )

    const keyRelated = (item : FAQ, index : number) => (item.id)

    return(
        //@ts-ignore
        <ScrollView ref={ref} style={styles.container} onScroll={() => console.log("Scroll")} >
            <HelpHeaderImage />
            <View style={styles.articlectn}>
                <ArticleCategoryList activecategory={faq.category.id} style={{flex : 1, maxWidth : 300}} />
                <View style={styles.faqctn}>
                    <Text style={styles.faqtitle}>{faq.title}</Text>
                    <Text style={styles.faqdesc}>{faq.description}</Text>
                    <View style={styles.relatedctn}>
                        <Text style={{fontWeight: 'bold', fontSize: 22}}>Related articles</Text>
                        <FlatList style={{marginVertical: 20}} data={getRelatedFAQs(faq.id)} renderItem={renderRelated} keyExtractor={keyRelated} numColumns={3} />
                    </View>
                </View>
            </View>
            <Footer />
        </ScrollView>
    )
}