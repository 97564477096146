import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { FAQ } from '../../src/constants/Structs';
import SmallFAQItem from '../../src/components/help/SmallFAQListItem';
import { useNavigation } from '@react-navigation/native';
import { HelpScreenNavigationProp } from '../../screens/HelpScreen';

interface Props{
    item : FAQ;
}

export default function SearchSumup({item} : Props) {

    const navigation = useNavigation<HelpScreenNavigationProp>()
    
    return(
        <View style={styles.container}>
            <SmallFAQItem item={item} style={{paddingHorizontal: 0}} onPress={() => navigation.navigate("Article", {id: item.id})} />
            <Text>{item.description}</Text>
        </View>
    )
    
}

const styles = StyleSheet.create({
    container: {
        padding: 20
    }
});