import React from "react";
import {useTerms} from "../constants/LegalConsts";
import MarkDownViewer from "../src/components/general/MarkDownViewer";

export default function TOSScreen() {
    const terms = useTerms("Terms")

    return(
        <MarkDownViewer markdown={terms} />
    )
}
