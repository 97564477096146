import { CompositeNavigationProp, RouteProp } from "@react-navigation/core";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Footer from "../components/Footer";
import ArticleView from "../components/help/ArticleView";
import CategoryView from "../components/help/CategoryView";
import DefaultHelpView from "../components/help/DefaultHelpView";
import HelpHeaderImage from "../components/help/HelpHeaderImage";
import { HelpStackParamList } from "../navigation/HelpNavigator";
import { DrawerParamList } from "../types";

type NProp = CompositeNavigationProp<DrawerNavigationProp<DrawerParamList>, StackNavigationProp<HelpStackParamList>>
type RProp = RouteProp<HelpStackParamList, 'StartHelp'>

interface Props {
    navigation : NProp
    route : RProp
}

const Styles = StyleSheet.create({
    container: {
        flex : 1,
    },
    helpctn: {
    }
})

export default function MainHelpScreen({route, navigation} : Props) {

    const {article, category} = route.params ?? {}

    return(
        <ScrollView style={Styles.container}>
            <HelpHeaderImage />
            <View>
                {
                    article ? (
                        <ArticleView articleID={article} />
                    ) : (category ? (
                        <CategoryView CategoryID={category.split("#")[0]} SubCategoryID={category.split("#")[1]} />
                    ) : (
                        <DefaultHelpView />
                    ))
                }
            </View>
            <Footer />
        </ScrollView>
    )
}