import React from "react";
import { StyleSheet, View } from "react-native";
import FAQCategories from "../../components/mobile/help/FAQCategories";
import HelpSearchBar from "../../components/mobile/help/HelpSearchBar";

export default function MainHelpScreen() {


    return(
        <View style={styles.container}>
            <FAQCategories />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {

    }
})
