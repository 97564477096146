import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import MarkDownComponent from "./MarkDownComponent";

interface Props {
    markdown : string
}

export default function MarkDownViewer({markdown} : Props) {

    return(
        <ScrollView>
            <View style={styles.container}>
                <MarkDownComponent>
                    {markdown}
                </MarkDownComponent>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: 20
    }
})
