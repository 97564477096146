import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import en from './locales/en'
import de from './locales/de'

interface i18ninterface{
    t : (scope : string, values? : {[key : string]: any}) => string
}

i18n.locale = Localization.locale;

i18n.fallbacks = true;
i18n.translations = {
    en,
    de
};

const Locale = i18n as i18ninterface

export default Locale