import React, { useLayoutEffect, useState } from 'react';
import { View, ImageBackground, Text , useWindowDimensions, StyleSheet, TextInput, FlatList, ScrollView} from 'react-native';
import { useSpring , animated} from 'react-spring/native';
import Footer from '../components/Footer';
import { Features } from '../constants/Features';
import StoreBadges from '../src/components/feature/StoreBadges';
import Locale from '../src/utils/locale/Locale';
import Feature from './../components/Feature';

export default function FeatureScreen(){

    const { height } = useWindowDimensions();
    const [firstscreen, setFirstscreen] = useState(true);
    const firstspring = useSpring(firstscreen ? {opacity: 1} : {opacity : 0});

    const onScroll = () => {
        if(window.scrollY > height * 0.2){
            setFirstscreen(false);
        } else {
            setFirstscreen(true);
        }
        console.log("onScroll : ",window.scrollY)
    };

    useLayoutEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll)
    }, []);

    const styles = StyleSheet.create({
        container: {
          flex: 1,
          alignItems: 'center',
        },
        title: {
          fontSize: 45,
          fontWeight: 'bold',
          color: 'white',
          textAlign: 'center'
        },
        description: {
            fontSize: 25,
            color: 'white',
            textAlign: 'center'
        },
        separator: {
          marginVertical: 30,
          height: 1,
          width: '80%',
          backgroundColor: 'grey'
        },
        titletextinput: {
          
        },
        ctntitleinput: {
          padding: 20,
          backgroundColor: 'white',
          borderRadius: 25,
          width: "50%"
        },
        imgbackground: {
          width: '100%',
          height: height,
          justifyContent: 'center',
          alignItems: 'center'
        },
        faqlistctn: {
          margin: 20,
          alignItems: 'flex-start',
          width: '90%'
        },
        faqlist: {
          flex: 0
        },
        lowertitle: {
          fontSize: 40,
          fontWeight: 'bold',
          color: 'black',
          textAlign: 'left'
        },
        text: {
          color: "black"
        }
      });

    const featurerender = ({item}) => (
        <Feature item={item} />
    );

    const keyfeatures = (item, index) => (
        index
    )

    const AnimatedView = animated(View);

    return (
        <View>
            <ImageBackground style={[styles.imgbackground]} source={require("./../assets/images/helpbackground.jpg")}>
                <AnimatedView style={{alignItems: 'center', ...firstspring}}>
                  <Text style={styles.title}>{Locale.t("home_title")}</Text>
                  <Text style={styles.description}>{Locale.t("home_description")}</Text>
                </AnimatedView>
            </ImageBackground>
            <FlatList style={{marginVertical: 50}} renderItem={featurerender} data={Features} keyExtractor={keyfeatures} />
            <StoreBadges />
            <Footer />
        </View>


    )
}