import { FirstAd, maincategories } from "../data/Categorys";
import { Category } from "../constants/Structs";


export default class CategoryMemory {
    static categories? : Category[];
    static parents : {[id : string]:Category} = {};

    static getCategory(id : string) {
        if(!CategoryMemory.categories){
            this.saveAllCategories();
        }
        return CategoryMemory.categories?.find((v) => v.id === id) ?? FirstAd;
    }

    static getSubCategory(id : string) {
        return this.getCategory(id).subs;
    }

    static getParentCategory(id : string) : Category | undefined {
        if(!CategoryMemory.categories){
            this.saveAllCategories();
        }
        return this.parents[id];
    }

    private static saveAllCategories() {
        const parseCategories = (category : Category[]) => {
            const list : Category[] = [];
            category.forEach((c) => {
                if(c.subs){
                    const iter = parseCategories(c.subs);
                    list.push(...iter);
                    iter.forEach((v) => {
                        this.parents[v.id] = c;
                    })
                } else {
                    list.push(c);
                }
            });
            return list;
        }

        const ret = parseCategories(maincategories);
        CategoryMemory.categories = ret;
    }
}

export function isMain(id : string) {
    return maincategories.find((v) => v.id === id) !== undefined;
}