import React from "react";
import { ImageBackground, StyleSheet, Text, useWindowDimensions, View } from "react-native";
import StoreBadges from "../../components/mobile/feature/StoreBadges";

export default function FeatureScreen() {

    const {height} = useWindowDimensions()

    return(
        <View>
            <ImageBackground style={[styles.imagebackground, {height: height}]} source={require('../../../assets/images/helpbackground.jpg')}>
                <Text style={[styles.title, {color: 'white'}]}>Chat with people you really </Text>
            </ImageBackground>
            <StoreBadges />
        </View>
    )
}

const styles = StyleSheet.create({
    imagebackground: {
        width: "100%",
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center'
    }
})
