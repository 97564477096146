import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { ImageBackground, StyleSheet, Text, TextInput, useWindowDimensions, View } from 'react-native'
import { HelpStackParamList } from '../../navigation/HelpNavigator';
import Locale from '../../src/utils/locale/Locale';
import { RootStackParamList } from '../../types';

interface Props{
    input? : string
}

type NProp = StackNavigationProp<HelpStackParamList>

export default function HelpHeaderImage({input} : Props){

    const { height } = useWindowDimensions();
    const [querry, setQuerry] = useState(input ?? '');
    const navigation = useNavigation<NProp>();

    const styles = StyleSheet.create({
        title: {
            fontSize: 40,
            fontWeight: 'bold',
            color: 'white'
        },
        imgbackground: {
            width: '100%',
            height: (height - (0.2 * height)),
            justifyContent: 'center',
            alignItems: 'center'
        },
        ctntitleinput: {
            padding: 20,
            backgroundColor: 'white',
            borderRadius: 25,
            width: "50%"
        },
    });

    return(
        <ImageBackground style={styles.imgbackground} source={require("./../../assets/images/helpbackground.jpg")}>
            <Text style={styles.title}>{Locale.t("help_needed")}</Text>
            <View style={styles.ctntitleinput}>
              <TextInput value={querry} onChangeText={(msg) => setQuerry(msg)} style={{outlineWidth: 0}} placeholder={"Ihre Frage ... "} onSubmitEditing={() => navigation.navigate("Search", {querry: querry})} />
            </View>
          </ImageBackground>
    );
}