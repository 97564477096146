import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ArticleScreen from '../screens/ArticleScreen';
import HelpSearchScreen from '../screens/HelpSearchScreen';
import { headerleft } from './BottomTabNavigator';
import Locale from '../src/utils/locale/Locale'
import MainHelpSelector from '../src/selectors/MainHelpSelector';
import CategorySelector from '../src/selectors/CategorySelector';
import useMobileVersion from '../src/hooks/useMobileVersion';
import ArticleScreenSelector from '../src/selectors/ArticleScreenSelector';

export type HelpStackParamList = {
    StartHelp: { category? : string, article? : string};
    Article: { id : string };
    Category: { id : string, sub? : string};
    Search: { querry : string };
}

const HelpStack = createStackNavigator<HelpStackParamList>();

export default function HelpNavigator(){

    const mobile = useMobileVersion()

    return (
        <HelpStack.Navigator
        initialRouteName="StartHelp"
        screenOptions={{animationEnabled: mobile, animationTypeForReplace: 'push'}}
        >
            <HelpStack.Screen name="StartHelp" component={MainHelpSelector} options={{headerLeft: headerleft, title: Locale.t("help")}} />
            <HelpStack.Screen name="Article" component={ArticleScreenSelector} options={{headerTitle: "Article"}} />
            <HelpStack.Screen name="Category" component={CategorySelector} options={{headerTitle: "Category"}} />
            <HelpStack.Screen name="Search" component={HelpSearchScreen} options={{headerLeft: headerleft, title: "Search"}} />
        </HelpStack.Navigator>
    )
}