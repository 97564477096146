import React, { useMemo } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { getAllCategoryFAQs } from '../../src/utils/FAQManager';
import { Category, FAQ } from '../../src/constants/Structs';
import SmallFAQItem from '../../src/components/help/SmallFAQListItem';

interface Props {
    item : Category;
    onPress : (item : FAQ) => void;
}

export default function CategorySumup({item, onPress} : Props) {

    const articles = useMemo(() => getAllCategoryFAQs(item.id), [item.id]);

    const styles = StyleSheet.create({
        container: {
            flex: 1
        },
        categorytitle: {
            fontSize: 20
        },
        listctn: {
            margin: 20,
        }
    });

    const renderFAQs = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} onPress={() => onPress(item)} />
    )

    return(
        <View style={styles.container}>
            <Text style={styles.categorytitle}>{item.title}</Text>
            <View style={styles.listctn}>
                <FlatList data={articles} renderItem={renderFAQs} />
            </View>
        </View>
    )
}