import React, { useCallback } from "react";
import { Image, TouchableOpacity } from "react-native";
import * as Linking from 'expo-linking';

interface Props {
    type : "AppStore" | "GooglePlay"
}

export default function BadgeImage({type} : Props) {

    const onPress = useCallback(() => {
        if(type == 'AppStore'){
            Linking.openURL("https://apps.apple.com/de/app/revoir/id1577388494?itsct=apps_box_link&itscg=30200")
        }else {
            Linking.openURL("https://play.google.com/store/apps/details?id=com.callmemaybe")
        }
    }, [type])

    return(
        <TouchableOpacity onPress={onPress}>
            <Image style={{height: 50, width: 140}} resizeMode="contain" source={type == "AppStore" ? require("../../../assets/images/badges/de/black.svg") : require("../../../assets/images/badges/de/google-play-badge.png")} />
        </TouchableOpacity>
    )

}