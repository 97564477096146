import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { FlatList } from "react-native";
import { getAllCategoryFAQs } from "../../src/utils/FAQManager";
import { Category, FAQ } from "../../src/constants/Structs";
import SmallFAQItem from "../../src/components/help/SmallFAQListItem";

interface Props{
    category : Category
    style? : StyleProp<ViewStyle>
    onPress : (item : FAQ) => void;
}

export default function FAQList({category, style, onPress} : Props) {

    const faqs = getAllCategoryFAQs(category.id)

    const render = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} onPress={() => onPress(item)} />
    )

    return(
        <FlatList data={faqs} renderItem={render} style={style} />
    )
}