
export type StyleType = "light" | "dark";

export interface Style{
    type : StyleType;
    accentcolor : string;
    componentcolor : string;
    textcolor : string;
}

export const light = {
    type: "light",
    accentcolor : "blue",
    componentcolor : "rgba(255,255,255,1)",
    textcolor : "black",
} as Style;

export const dark = {
    type: "dark",
    accentcolor : "lightblue",
    componentcolor : "#323232ff",
    textcolor: "white"
} as Style;