import { FAQ } from "../constants/Structs";
import * as Category from "./Categorys";


export const faqs : FAQ[] = [
    {
        title: "Wie bekomme ich diese App ?",
        description: "Diese App wird in Zukunft im Google Play Store und Apple Appstore kostenlos verfügbar sein.",
        id: "1",
        category: Category.FirstAd
    },
    {
        title: "Warum kann ich die Nummer meines Chatpartners nicht sehen ?",
        description: "Du kannst die Nummer der anderen Person erst sehen wenn die Person die die Anfrage bekommen hat sind auch dazu entschieden hat mit dir die Nummer zu teilen. Daraufhin können die Chatpartner die Nummer des jeweilig anderen sehen.",
        id: "2",
        category: Category.Works
    },
    {
        id: "3",
        title: "Warum ist die App erst ab 18 Jahren?",
        description: "Wir haben uns entschieden die App erst ab 18 Jahren freizugeben, da es sonst nötig wäre das Alter abzufragen um für die jüngeren einen eigenen Bereich zu erstellen.",
        category: Category.General
    },
    {
        id: "android-blekilled",
        title: 'Der Discovery Service wird immer wieder gestoppt',
        description: ``,
        category: Category.Android,
        markdown: `Dieses Problem tritt bei Android Geräten punktuell bei Herstellen auf die eine sehr agressive Batterieoptimierung besitzen. 
        Um dieses Problem zu lösen sollte überlegt werden die Batterieoptimierung für Revoir zu deaktivieren. 
        Genaue Information für dein jeweiliges Gerät kannst du auf der Seite von [Don't kill my app!](https://dontkillmyapp.com/) entnehmen.`
    },
    {
        id: "5",
        title: "Wie kann ich Coins bekommen?",
        description: `Du kannst Coins als In-App Kauf beziehen wenn du auf die Coins im Einstellungs Tab klickst.`,
        category: Category.Works
    }
];

export const pfaqs : string[] = ["1", "android-blekilled"]