import { RouteProp, useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import {getPrivacy, getTerms, useTerms} from "../constants/LegalConsts";
import { TermsParamList, TermsScreenNavigationProp } from "../navigation/TermsNavigator";
import MarkDownViewer from "../src/components/general/MarkDownViewer";
import Locale from "../src/utils/locale/Locale";

type ProfileScreenRouteProp = RouteProp<TermsParamList, 'LegacyTerms'>;

interface Props {
    route: ProfileScreenRouteProp;
}

export default function LegacyTermsScreen({route} : Props) {

    const {type} = route.params;

    const terms = useTerms(type)

    const navigation = useNavigation<TermsScreenNavigationProp>();

    useEffect(() => {
        navigation.setOptions({headerTitle : type == "Terms" ? Locale.t("tos") : Locale.t("privacy_policy")})
    }, [type])

    return(
        <MarkDownViewer markdown={terms} />
    )

}
