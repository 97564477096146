import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, useNavigation } from '@react-navigation/core';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { ListItem } from 'react-native-elements';
import { View } from '../components/Themed';
import { useTheme } from '../hooks/useTheme';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import {BottomTabParamList} from './../types'
import { headerleft } from './BottomTabNavigator';
import { CardStyleInterpolators } from '@react-navigation/stack';
import Locale from '../src/utils/locale/Locale'
import Impressum from '../screens/Impressum';
import TOSScreen from '../screens/TOSScreen';
import LegacyTermsScreen from '../screens/LegacyTermsScreen';

export type TermsParamList = {
    Root: undefined;
    Privacy: undefined;
    TOS: undefined;
    LegacyTerms: {type : "Privacy" | "Terms"};
    Impressum : undefined;
}

const TermsStack = createStackNavigator<TermsParamList>();

export type TermsScreenNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<BottomTabParamList, 'Terms'>,
  StackNavigationProp<TermsParamList>
>;

export default function TermsNavigator(){
    return(
        <TermsStack.Navigator
        initialRouteName="Root"
        screenOptions={{animationEnabled: true, cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS}}
        >
            <TermsStack.Screen name="Root" component={Legal} options={{title: Locale.t("terms"), headerLeft: headerleft}} />
            <TermsStack.Screen name="Privacy" component={PrivacyPolicy} options={{title: Locale.t("privacy_policy")}} />
            <TermsStack.Screen name="TOS" component={TOSScreen} options={{title: Locale.t("tos")}} />
            <TermsStack.Screen name="LegacyTerms" component={LegacyTermsScreen} />
            <TermsStack.Screen name="Impressum" component={Impressum} />
        </TermsStack.Navigator>
    )
}

const Legal = () => {

    const Style = useTheme();
    const navigator = useNavigation<TermsScreenNavigationProp>();

    const Styles = StyleSheet.create({
        imgcontainer: {
            flex : 1,
            justifyContent: 'center'
        },
        listitem:{
            backgroundColor : "white",
        },
        listcontainer:{
            borderRadius : 25,
            overflow: 'hidden',
            margin: 20
        }
    });
    

    return(
        <ScrollView style={{flex : 1}}>
            <View style={Styles.listcontainer}>
                <ListItem containerStyle={Styles.listitem} onPress={() => navigator.navigate("Privacy")}>
                    <ListItem.Content style={{flex: 1, flexDirection : 'row',alignContent : 'flex-start'}}>
                        <ListItem.Title style={{color: Style.textcolor}}>{Locale.t("privacy_policy")}</ListItem.Title>
                    </ListItem.Content>
                </ListItem>
                <ListItem containerStyle={Styles.listitem} onPress={() => navigator.navigate("TOS")}>
                    <ListItem.Content style={{flex: 1, flexDirection : 'row',alignContent : 'flex-start'}}>
                        <ListItem.Title style={{color: Style.textcolor}}>{Locale.t("tos")}</ListItem.Title>
                    </ListItem.Content>
                </ListItem>
                <ListItem containerStyle={Styles.listitem} onPress={() => navigator.navigate("Impressum")}>
                    <ListItem.Content style={{flex: 1, flexDirection : 'row',alignContent : 'flex-start'}}>
                        <ListItem.Title style={{color: Style.textcolor}}>{Locale.t("legal")}</ListItem.Title>
                    </ListItem.Content>
                </ListItem>
            </View>
        </ScrollView>
    )
}