import React from "react";
import {  StyleSheet, Text, useWindowDimensions, View } from "react-native";
import Locale from "../../../utils/locale/Locale";
import BadgeImage from "../../general/BadgeImage";

export default function StoreBadges() {

    const {height} = useWindowDimensions()

    return(
        <View style={[styles.container, {height: height}]}>
            <Text style={styles.title}>{Locale.t("findus")}</Text>
            <View>
                <BadgeImage type="AppStore" />
                <BadgeImage type="GooglePlay" />
            </View>
            <View style={{position: 'absolute', bottom: 5, alignItems: 'center'}}>
                <Text style={styles.legaltext}>App Store ist eine Marke von Apple Inc.</Text>
                <Text style={styles.legaltext}>Google Play und das Google Play-Logo sind Marken von Google LLC.</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    legaltext: {
        textAlign: 'center',
        fontSize: 9
    }
})