import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { TermsParamList } from '../navigation/TermsNavigator';
import { DrawerParamList } from '../types';
import * as WebBrowser from 'expo-web-browser';
import Locale from '../src/utils/locale/Locale';
import * as Linking from 'expo-linking';

type NProp = CompositeNavigationProp<DrawerNavigationProp<DrawerParamList>, StackNavigationProp<TermsParamList>>

type Link = 'About' | 'Contact'

export default function Footer() {

    const navigation = useNavigation<NProp>();

    const openNewLink = useCallback((link : Link) => {
        WebBrowser.openBrowserAsync(linktoURL(link))
    }, [])

    return (
        <View style={styles.container} >
            <View style={styles.containerrow}>
                <View style={styles.firstrowctn}>
                    <Text style={styles.columntitle}>FrozenFacesApps</Text>
                    <Text style={styles.columnitems}>Blog</Text>
                    <Text style={styles.columnitems} onPress={() => openNewLink('About')}>{Locale.t("footer_aboutus")}</Text>
                    <Text style={styles.columnitems} onPress={() => openNewLink('Contact')}>{Locale.t("footer_contact")}</Text>
                </View>
                <View style={styles.firstrowctn}>
                    <Text style={styles.columntitle}>Stores</Text>
                    <Text style={styles.columnitems} onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.callmemaybe")}>Google Play</Text>
                    <Text style={styles.columnitems} onPress={() => Linking.openURL("https://apps.apple.com/de/app/revoir/id1577388494?itsct=apps_box_link&itscg=30200")}>App Store</Text>
                </View>
            </View>
            <View style={styles.lastrow}>
                <View style={styles.lastleft}>
                    <Text style={styles.smalltext} onPress={() => navigation.navigate("Terms", {screen: 'TOS'})}>{Locale.t("tos")}</Text>
                    <Text style={styles.smalltext} onPress={() => navigation.navigate("Terms", {screen: 'Privacy'})}>{Locale.t("privacy_policy")}</Text>
                    <Text style={styles.smalltext} onPress={() => navigation.navigate("Terms", {screen: 'Impressum'})}>{Locale.t("legal")}</Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'rgba(50,50,50,0.1)',
        width: '100%',
        height: 300,
        paddingVertical: 20,
        paddingHorizontal: 5,
        justifyContent: 'flex-end'
    },
    containerrow: {
        flexDirection: 'row',
        alignSelf: 'center',
        width: "80%",
        margin: 20
    },
    firstrowctn: {
        alignItems: 'flex-start',
        marginRight: 40
    },
    columntitle:{
        fontSize: 17,
        fontWeight: '100',
        marginBottom: 12,
        color: 'rgba(100,100,100,1)'
    },
    columnitems: {
        fontSize: 15,
        fontWeight: '600',
        color: 'rgba(100,100,100,1)',
        textAlign: 'left',
        marginBottom: 10
    },
    lastrow: {
        width: "80%",
        alignSelf: 'center',
    },
    lastleft: {
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    smalltext: {
        fontSize: 12,
        marginHorizontal: 10,
        color: 'rgba(100,100,100,1)'
    }
});

function linktoURL(link : Link) : string {
    switch(link){
        case 'Contact':{
            return 'mailto:admin@frozenfacesapps.de?subject=Revoir'
        }

        case 'About':
        default:{
            return 'https://frozenfacesapps.de/'
        }
    }
}