export default {
    home: "Home",
    home_title: "Chat with people you really meet",
    home_description: "Walking down the street and meeting some nice people and don't know how to contact them we have the solution",
    feature1_title: "It all starts with seeing the other person",
    feature1_description: "Revoir is the app that shows you the people you have really seen",
    findus: "Find us",
    help: "Help",
    help_needed: "Need Help?",
    help_related_articles: "Related articles",
    terms: "Terms",
    popular_faq: "Popular FAQs",
    search_result: "Got %{results} results for '%{query}'",
    footer_aboutus: "About us",
    footer_contact: "Contact",
    legal: "Legal",
    tos: "Term of Use",
    privacy_policy: "Privacy Policy",
    //Help section
    first_ad: "First ad",
    fa_howdoesitwork: "How does it work",
    help_general: "General Help",
}