import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Category } from '../../constants/Structs';

interface Prop {
    item : Category;
    style : StyleProp<ViewStyle>
    onPress? : (() => void);
}

export default function CategoryListItem({ item , style, onPress} : Prop){


    return (
        <TouchableOpacity containerStyle={[styles.container, style]} onPress={onPress} >
            <View style={styles.innerctn}>
                <Icon name={item.icon?.name ?? "rowing"} size={60} type={item.icon?.type} />
                <View style={styles.textctn}>
                    <Text style={styles.title} >{item.title}</Text>
                    <Text style={styles.description}>{item.description}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 30,
        margin: 10,
        borderWidth: 1,
        borderColor: 'grey',
        borderRadius: 25,
        shadowColor: 'grey',
        shadowOpacity: 1,
        shadowRadius: 5,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        width: "100%"
    },
    description: {
        fontSize: 17,
        fontWeight: '200',
        width: "100%"
    },
    innerctn: {
        flexDirection: 'row',
        flex: 1,
    },
    textctn: {
        flex: 1,
        marginLeft: 10
    }
});