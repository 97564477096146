import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { HelpStackParamList } from "../../navigation/HelpNavigator";
import DesktopArticleScreen from "../../screens/ArticleScreen";
import useMobileVersion from "../hooks/useMobileVersion";
import ArticleScreen from "../screens/mobile/ArticleScreen";

type RProp = RouteProp<HelpStackParamList, 'Article'>
type NProp = StackNavigationProp<HelpStackParamList>

interface Props {
    route : RProp;
    navigation : NProp
}

export default function ArticleScreenSelector({route, navigation} : Props) {

    const mobile = useMobileVersion()

    if(mobile) {
        return(<ArticleScreen navigation={navigation} route={route} />)
    }
    return <DesktopArticleScreen route={route} />
}