import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { FlatList, StyleSheet, Text, useWindowDimensions, View } from "react-native";
import Animated, { useAnimatedStyle, withSpring } from "react-native-reanimated";
import { HelpStackParamList } from "../../../navigation/HelpNavigator";
import { Category } from "../../constants/Structs";
import { maincategories } from "../../data/Categorys";
import FAQListItem from './FAQListItem';

interface Props {
    visible? : boolean
}

type NProp = StackNavigationProp<HelpStackParamList>

export default function FAQCategories({visible} : Props) {

    const {width} = useWindowDimensions()

    const navigation = useNavigation<NProp>()

    const columns = () => {
        const ret = Math.floor(width / 400);
        console.log(ret);
        return ret;
    }

    const style = useAnimatedStyle(() => {
        return {
            opacity: withSpring(visible ? 1 : 0)
        }
    })

    const render = ({item} : {item : Category}) => (
        <Animated.View style={style}>
            <FAQListItem item={item} style={{}} onPress={() => navigation.navigate("Category", {id : item.id})} />
        </Animated.View>
    )

    return(
        <View style={styles.faqlistctn}>
            <Text style={styles.lowertitle}>FAQ</Text>
            <FlatList style={{width: '100%', alignSelf: 'center', flex: 1}} contentContainerStyle={{}} columnWrapperStyle={{}} numColumns={columns()} key={columns()} data={maincategories} renderItem={render} />
        </View>
    )
}

const styles = StyleSheet.create({
    faqlistctn: {
        flex: 1,
        alignSelf: 'center',
        maxWidth: '85%'
      },
    lowertitle: {
      fontSize: 40,
      fontWeight: '400',
      color: 'black',
      textAlign: 'left'
    },
    text: {
      color: "black"
    },
    categories: {
      width: "80%"
    }
});