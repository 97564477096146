import React from 'react'
import { Text, View, FlatList, StyleSheet } from 'react-native'
import { getFAQ, getRelatedFAQs } from '../../src/utils/FAQManager';
import { FAQ } from '../../src/constants/Structs';
import SmallFAQItem from '../../src/components/help/SmallFAQListItem';
import ArticleCategoryList from './ArticleCategoryList'
import Markdown from 'react-markdown';
import Locale from '../../src/utils/locale/Locale';
import MarkDownComponent from "../../src/components/general/MarkDownComponent";

interface Props{
    articleID : string
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    viewctn: {

    },
    faqctn: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "80%",
        marginStart: 40
    },
    faqtitle: {
        fontSize: 30,
        marginBottom: 20
    },
    faqdesc: {
        fontSize: 20,
    },
    articlectn: {
        flexDirection : 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        marginVertical: 50,
        width: "80%"
    },
    relatedctn: {
        marginTop: 40,
        width: "100%"
    }
});

export default function ArticleView({articleID} : Props) {

    const faq = getFAQ(articleID);

    if(!faq) {
        return null
    }

    const renderRelated = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} />
    )

    const keyRelated = (item : FAQ, index : number) => (item.id)

    return(
        <View style={styles.articlectn}>
            <ArticleCategoryList activecategory={faq.category.id} style={{flex : 1, maxWidth : 300}} />
            <View style={styles.faqctn}>
                <Text style={styles.faqtitle}>{faq.title}</Text>
                {
                    faq.markdown ? (
                        <MarkDownComponent>{faq.markdown}</MarkDownComponent>
                    ) : (
                        <Text style={styles.faqdesc}>{faq.description}</Text>
                    )
                }
                <View style={styles.relatedctn}>
                    <Text style={{fontWeight: 'bold', fontSize: 22}}>{Locale.t("help_related_articles")}</Text>
                    <FlatList style={{marginVertical: 20}} data={getRelatedFAQs(faq.id)} renderItem={renderRelated} keyExtractor={keyRelated} numColumns={3} />
                </View>
            </View>
        </View>
    )
}
