import React from 'react';
import {useTerms} from '../constants/LegalConsts';
import MarkDownViewer from '../src/components/general/MarkDownViewer';

export default function PrivacyPolicy(){
    const privacy = useTerms("Privacy")

    return(
        <MarkDownViewer markdown={privacy} />
    )
}
