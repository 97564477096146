import { Category } from "../constants/Structs";
import Locale from "../utils/locale/Locale";

export const Works : Category = {
    title: Locale.t("fa_howdoesitwork"),
    id: "works"
}

export const FirstAd : Category = {
    title: Locale.t("first_ad"),
    id: "fad",
    subs: [Works],
    description: "Erste Anlaufstelle bei einfachen Problemen",
    icon: {
        name: "first-aid",
        type: "foundation"
    }
}

export const General : Category = {
    title: Locale.t("help_general"),
    id: "general",
    description: "Allgemeinen Probleme",
    icon: {
        name: 'notification',
        type: 'antdesign'
    }
}

export const Android : Category = {
    title: "Android",
    id: "android",
    description: "Hilfe für die Android Version",
    icon: {
        name: "android",
        type: "material"
    }
}

export const iOS : Category = {
    title: "iOS",
    id: "ios",
    description: "Hilfe für die iOS Version von Revoir",
    icon: {
        name: "apple-ios",
        type: "material-community"
    }
}

//Haupt Kategorien

export const maincategories = [
    FirstAd, General, Android, iOS
];
