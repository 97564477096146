import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { FlatList, StyleProp, Text, View, ViewStyle , Pressable} from 'react-native';
import { Category } from '../../src/constants/Structs';
import { HelpStackParamList } from '../../navigation/HelpNavigator';
import { maincategories } from '../../src/data/Categorys';
import CategoryMemory, { isMain } from '../../src/utils/CategoryMemory';

const Item = ({item, onPress, active} : {item: TreeNode, active: boolean, onPress?: () => void}) => {
    const main = item.sub === 0;

    const children = (state : {hovered : boolean, pressed : boolean}) => (
        <View style={{
            backgroundColor: active ? "rgba(50,50,50,0.1)" : "transparent",
            borderRadius: 10,
        }}>
            <Text style={{
            textAlign: 'left',
            fontSize: 16,
            padding: 15,
            fontWeight: main ? 'bold' : state.hovered ? '500' :'normal',
            width: "100%"
        }}>{item.item.title}</Text>
        </View>
    )
    return(
        <Pressable 
        style={{flex : 1}}
        onPress={onPress}
        children={children}
        />
    )
}

interface TreeNode{
    item : Category;
    sub: number;
}

interface Props{
    activecategory : string;
    style? : StyleProp<ViewStyle>
}

type NProp = StackNavigationProp<HelpStackParamList>

export default function ArticleCategoryList({activecategory , style} : Props) {

    const navigation = useNavigation<NProp>();

    const generatelist = (categorys : Category[] = maincategories ,sub : number = 0) => {
        const list : TreeNode[] = [];
        categorys.forEach((v) => {
            if(!v.subs){
                list.push({item: v, sub: sub} as TreeNode)
            } else {
                list.push({item: v, sub: sub} as TreeNode);
                list.push(...generatelist(v.subs, sub + 1));
            }
        });
        return list;
    }

    const renderItem = ({item} : {item : TreeNode}) => (
        <Item item={item} active={item.item.id === activecategory} onPress={() => {
            if(item.item.subs || isMain(item.item.id)){
                navigation.navigate("StartHelp", {category : item.item.id, article: undefined})
            } else {
                const parent = CategoryMemory.getParentCategory(item.item.id);
                console.log("parent", parent)
                if(parent) {
                    navigation.navigate("StartHelp", {category : `${parent.id}#${item.item.id}`, article: undefined})
                }
            }
        }} />
    )

    const keyExtractor = (item : TreeNode, index : number) => (index.toString())


    return(
        <View style={style}>
            <FlatList data={generatelist()} renderItem={renderItem} keyExtractor={keyExtractor} />
        </View>
    )
}