import { faqs, pfaqs } from "../data/FAQs";
import { FAQ } from "../constants/Structs";
import { FirstAd } from "../data/Categorys";


export function getFAQ(id : string) {
    return faqs.find((v) => v.id === id);
}

export function getPopularFAQs() {
    return pfaqs.map((v) => (getFAQ(v) ?? mockfaq));
}

export function getRelatedFAQs(id : string) {
    const categoryID = getFAQ(id)?.category.id
    const faqs = categoryID ? getAllCategoryFAQs(categoryID).filter((v) => v.id != id) : []
    return faqs.length > 0 ? faqs : getPopularFAQs()
}

export function getAllCategoryFAQs(id : string) {
    return faqs.filter((v) => {
        if(v.category.id === id){
            return true;
        }
        return false;
    })
}

export function getAllFAQs() {
    return faqs;
}

const mockfaq : FAQ = {
    title: "Test FAQ : BLALBLALBLALBLLA ?",
    description: "DAS IST EIN TEST BLFBLDFBLDFLBLDFBLDFBLDFLBLDFBLDFBLDLFBLDBLDFLBLDFBLDBLDE",
    id: "12345678",
    category: FirstAd
}