import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from '../types';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              TabOneScreen: '/',
            },
          },
          Help: {
            initialRouteName: "StartHelp",
            path: "help",
            screens: {
              StartHelp: "/",
              Article: {
                path: "article/:id"
              },
              Category: {
                path: "category/:id/:sub?"
              },
              Search: "search"
            }
          },
          Terms: {
            initialRouteName: "Root",
            path: "legals",
            screens: {
              Root: "/",
              Privacy: {
                path: "privacy"
              },
              TOS: "tos",
              LegacyTerms: "terms/:type",
              Impressum: {
                path: "legal"
              }
            }
          }
        },
      },
      NotFound: '*',
    },
  },
} as LinkingOptions<RootStackParamList>;
