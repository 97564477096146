import { useNavigation } from "@react-navigation/core";
import React from "react";
import { useWindowDimensions } from "react-native";
import { View, Text, FlatList, StyleSheet } from "react-native";
import { Category, FAQ } from "../../src/constants/Structs";
import SmallFAQItem from "../../src/components/help/SmallFAQListItem";
import CategoryListItem from "../../src/components/help/FAQListItem";
import { StackNavigationProp } from "@react-navigation/stack";
import { HelpStackParamList } from "../../navigation/HelpNavigator";
import FAQCategories from "../../src/components/help/FAQCategories";
import PopularFAQs from "./PopularFAQs";

const styles = StyleSheet.create({
    ctntitleinput: {
      padding: 20,
      backgroundColor: 'white',
      borderRadius: 25,
      width: "50%"
    },
    faqlistctn: {
      margin: 20,
      alignItems: 'flex-start',
    },
    faqlist: {
      flex: 0
    },
    lowertitle: {
      fontSize: 40,
      fontWeight: '400',
      color: 'black',
      textAlign: 'left'
    },
    text: {
      color: "black"
    },
    categories: {
      width: "80%"
    }
  });

type NProp = StackNavigationProp<HelpStackParamList>

export default function DefaultHelpView() {

    const navigation = useNavigation<NProp>()
    const {width} = useWindowDimensions()

    const render = ({item} : {item : Category}) => (
        <CategoryListItem item={item} style={{}} onPress={() => navigation.navigate("StartHelp", {category: item.id})} />
      )
    
      const renderrecentfaq = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} />
      )
    
      const columns = () => {
        const ret = Math.floor(width / 400);
        console.log(ret);
        return ret;
      }

    return(
        <View style={{}} >
            <FAQCategories visible />
            <PopularFAQs style={{margin: 20}} />
        </View>
    )
}