import { RouteProp } from "@react-navigation/core";
import React from "react";
import { HelpStackParamList } from "../../navigation/HelpNavigator";
import CategoryScreen from "../../screens/CategoryScreen";
import useMobileVersion from "../hooks/useMobileVersion";
import MobileCategoryScreen from "../screens/mobile/CategoryScreen";

type CategoryRouteProp = RouteProp<HelpStackParamList, "Category">;

interface Props {
    route : CategoryRouteProp;
}

export default function CategorySelector({route} : Props) {
    const mobile = useMobileVersion()

    if(mobile) {
        return (<MobileCategoryScreen route={route} />)
    }

    return(<CategoryScreen route={route} />)
}