import React from 'react';
import { FlatList, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { getPopularFAQs } from '../../src/utils/FAQManager';
import { FAQ } from '../../src/constants/Structs';
import SmallFAQItem from '../../src/components/help/SmallFAQListItem';
import Locale from '../../src/utils/locale/Locale';

interface Props{
    style?: StyleProp<ViewStyle>;
}

export default function PopularFAQs({style} : Props) {

    const faqs : FAQ[] = getPopularFAQs();

    const styles = StyleSheet.create({
        container: {

        },
        title: {
            fontSize: 22
        },
        listctn: {
            marginVertical: 20
        }
    });

    const renderItems = ({item} : {item : FAQ}) => (
        <SmallFAQItem item={item} />
    )

    return(
        <View style={[styles.container, style]}>
            <Text style={styles.title}>{Locale.t('popular_faq')}</Text>
            <View style={styles.listctn}>
                <FlatList data={faqs} renderItem={renderItems} />
            </View>
        </View>
    )

}