import {useEffect, useState} from "react";


export let Terms = ""

export let Privacy = ""

export async function getTerms() {
    if(Terms.length == 0) {
        Terms = await (fetch(require('../terms/Terms.md')).then((r) => r.text()))
    }
    return Terms
}

export async function getPrivacy() {
    if(Privacy.length == 0) {
        Privacy = await (fetch(require('../terms/Privacy.md')).then((r) => r.text()))
    }
    return Privacy
}

getTerms().catch(console.warn)
getPrivacy().catch(console.warn)

export function useTerms(type: "Terms" | "Privacy") {
    const [terms, setTerms] = useState(type == "Terms" ? Terms : Privacy)

    useEffect(() => {
        const fkt = async () => {
            if(terms.length == 0) {
                setTerms(type == "Terms" ? await getTerms() : await getPrivacy())
            } else {
                console.log("Terms already present")
            }
        }
        fkt().catch(console.warn)
    })

    console.log("Terms: ", terms)

    return terms
}

