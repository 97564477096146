import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { Pressable, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import {Icon} from 'react-native-elements'
import { FAQ } from '../../constants/Structs';
import { HelpScreenNavigationProp } from '../../../screens/HelpScreen';

interface Prop{
    item : FAQ;
    style?: StyleProp<ViewStyle>;
    onPress? : () => void;
}

export default function SmallFAQItem({item, style, onPress} : Prop) {

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            paddingHorizontal: 20,
        },
        icon:{
            marginEnd: 5,
        }
    });

    return(
        <Pressable style={[styles.container, style]} onPress={onPress} >
            <Icon name="comment-question-outline" type="material-community" style={styles.icon} />
            <Text style={{flex: 1}}>{item.title}</Text>
        </Pressable>
    );
}