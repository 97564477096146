import Markdown from "react-markdown";
import 'github-markdown-css/github-markdown-light.css'
import './markdown.css'

interface Props {
    children: string
}

export default function MarkDownComponent({children} : Props) {
    return(
        <Markdown className={"markdown-body"}>
            {children}
        </Markdown>
    )
}
