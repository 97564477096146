import React from "react";
import DesktopFeatureScreen from "../../screens/FeatureScreen";
import useMobileVersion from "../hooks/useMobileVersion";
import MobileFeatureScreen from "../screens/mobile/FeatureScreen";

export default function FeatureScreenSelector() {

    const mobile = useMobileVersion()

    if(mobile) {
        return(
            <MobileFeatureScreen />
        )
    }

    return(
        <DesktopFeatureScreen />
    )
}