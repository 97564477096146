import React, { useEffect, useMemo, useRef } from 'react';
import { RouteProp } from '@react-navigation/core';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import Footer from '../components/Footer';
import ArticleCategoryList from '../components/help/ArticleCategoryList';
import HelpHeaderImage from '../components/help/HelpHeaderImage';
import { Category } from '../src/constants/Structs';
import { HelpStackParamList } from '../navigation/HelpNavigator';
import CategorySumup from '../components/categories/CategorySumup';
import CategoryMemory from '../src/utils/CategoryMemory';

type CategoryRouteProp = RouteProp<HelpStackParamList, "Category">;

interface Props {
    route : CategoryRouteProp;
}

export default function CategoryScreen({route} : Props) {

    let {id, sub} = route.params;

    const category = useMemo(() => CategoryMemory.getCategory(id), [id]);

    const subcategory = useMemo(() => CategoryMemory.getSubCategory(id), [id]);

    const ref = useRef<FlatList<Category>>();
    
    if(!subcategory){
        //TODO : Maybe show single faqs ???
    }

    useEffect(() => {
        if(sub && subcategory){
            const index = subcategory.findIndex((v) => v.id === sub);
            if(index > 0){
                ref.current?.scrollToIndex({index: index, animated: true});
            }
        }
    }, [])

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        viewctn: {
            
        },
        faqctn: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: "80%",
            marginStart: 40
        },
        faqtitle: {
            fontSize: 30,
            marginBottom: 20
        },
        faqdesc: {
            fontSize: 20,
        },
        articlectn: {
            flexDirection : 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            marginVertical: 50,
            width: "80%"
        },
        relatedctn: {
            marginTop: 40,
            width: "100%"
        }
    });

    const renderCategory = ({item} : {item : Category}) => (
        <CategorySumup item={item} onPress={(item) => {}} />
    )

    const keygen = (item : Category, index : number) => (item.id);

    return(
        <View style={styles.container} >
            <HelpHeaderImage />
            <View style={styles.articlectn}>
                <ArticleCategoryList activecategory={id} style={{flex : 1, maxWidth : 300}} />
                <View style={styles.faqctn}>
                    <Text style={styles.faqtitle}>{category.title}</Text>
                    <View>
                        <FlatList data={subcategory} renderItem={renderCategory} ref={ref} numColumns={3} />
                    </View>
                </View>
            </View>
            <Footer />
        </View>
    )
}