import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useTheme } from '../hooks/useTheme';

export default function RootHeader(){

    const Style = useTheme();

    const style = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            height: '100%',
            alignItems: 'center'
        },
        logo: {
            height: 40,
            width: 32,
            padding: 2
        },
        logotitle: {
            flex: 1,
            textAlign: 'center',
            textAlignVertical: 'center',
            alignSelf: 'center',
            fontSize: 30,
            fontWeight: 'bold',
            marginLeft: 10
        },
        logoctn: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#f47e80',
            borderRadius: 25,
            padding: 5,
            paddingHorizontal: 20
        }
    });

    return(
        <View style={style.container}>
            <View style={style.logoctn} >
                <Image style={style.logo} resizeMethod="auto" source={require("./../assets/images/logo3icon.png")} />
                <Text style={style.logotitle}>Revoir</Text>
            </View>
        </View>
    )
}