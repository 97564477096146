import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Locale from "../../utils/locale/Locale";
import BadgeImage from "../general/BadgeImage";

export default function StoreBadges() {

    return(
        <View style={styles.container}>
            <Text style={styles.title}>{Locale.t("findus")}</Text>
            <View style={{flexDirection: 'row', width: 350, justifyContent: 'space-around'}}>
                <BadgeImage type="AppStore" />
                <BadgeImage type="GooglePlay" />
            </View>
            <Text style={{position: 'absolute', bottom: 5}}>App Store ist eine Marke von Apple Inc. Google Play und das Google Play-Logo sind Marken von Google LLC.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 300
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10
    }
})