import React from "react";
import { StyleSheet, Text, View } from "react-native";

const Styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 25
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    bigtitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 50,
    },
    content: {
        marginTop: 20
    },
    infoctn: {
        width: '50%',
        alignItems: 'flex-start'
    }
})

export default function Impressum() {

    return(
        <View style={Styles.container}>
            <Text style={Styles.bigtitle}>Impressum</Text>
            <View style={Styles.infoctn}>
                <TitleLayout title={"Name"} text={"Florian Maly & Konstantin Späth GbR"} />
                <TitleLayout title={"Adresse"} text={"Geranienweg 5\n85598 Baldham\nDeutschland"} />
                <TitleLayout title={"E-Mail:"} text={"admin@frozenfacesapps.de"} />
                <TitleLayout title={"Umsatzsteuer-ID:"} text={"DE343725025"} />
            </View>
        </View>
    )
}

const TitleLayout = ({title, text} : {title : string, text : string}) => {
    return(
        <View style={{marginVertical: 25}}>
            <Text style={Styles.title}>{title}</Text>
            <Text style={{marginTop: 5}}>{text}</Text>
        </View>
    )
}
