import { Ionicons } from '@expo/vector-icons';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator, DrawerNavigationProp } from '@react-navigation/drawer';
import * as React from 'react';
import { BottomTabParamList, DrawerParamList, TabOneParamList } from '../types';
import TermsNavigator from './TermsNavigator';
import { useNavigation } from '@react-navigation/core';
import { Icon } from 'react-native-elements';
import RootHeader from './RootHeader';
import HelpNavigator from './HelpNavigator';
import { useTheme } from '../hooks/useTheme';
import Locale from '../src/utils/locale/Locale'
import FeatureScreenSelector from '../src/selectors/FeatureScreenSelector';

const Drawer = createDrawerNavigator<DrawerParamList>();

export function DrawerNavigator() {
  const Style = useTheme();

  return(
    <Drawer.Navigator
    initialRouteName="Home"
    screenOptions={{headerShown: false}}
    detachInactiveScreens
    >
      <Drawer.Screen name="Home" component={TabOneNavigator} options={{title: Locale.t("home")}} />
      <Drawer.Screen name="Help" component={HelpNavigator} options={{title: Locale.t("help")}} />
      <Drawer.Screen name="Terms" component={TermsNavigator} options={{title: Locale.t("terms")}} />
    </Drawer.Navigator>
  )
} 

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabOneStack = createStackNavigator<TabOneParamList>();

export const headerleft = () => {
  const navigator = useNavigation<DrawerNavigationProp<BottomTabParamList, "TabOne">>();
  return(
    <Icon name="menu" containerStyle={{marginLeft: 20}} onPress={() => navigator.toggleDrawer()} />
  )
}

export const headerleftwhite = () => {
  const navigator = useNavigation<DrawerNavigationProp<BottomTabParamList, "TabOne">>();
  return(
    <Icon name="menu" color={"white"} containerStyle={{marginLeft: 20}} onPress={() => navigator.toggleDrawer()} />
  )
}

function TabOneNavigator() {
  return (
    <TabOneStack.Navigator
    screenOptions={{
      headerTransparent: true
    }}
    >
      <TabOneStack.Screen
        name="TabOneScreen"
        component={FeatureScreenSelector}
        options={{ headerLeft: headerleftwhite, headerTitle: RootHeader, title: "Revoir"}}
      />
    </TabOneStack.Navigator>
  );
}
